import React, { StrictMode } from 'react';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@assets/css/base.css';

/* eslint-disable react-refresh/only-export-components */
const AppLazy = React.lazy(() => import('src/app'));
const Container = document.getElementById('root');

if (Container && !Container.innerHTML) {
  const root = createRoot(Container);
  root.render(
    <StrictMode>
      <AppLazy />
    </StrictMode>
  );
}
